import logo from './logo.svg';
import 'antd/dist/antd.min.css';
import { useStateValue } from './Component/StateProviders';
import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom"
import Schedule_Timing from './Component/Doctor/Schedule_Timings';
import Patient_Booking from './Component/Patient/Patient_Booking';
import Doctor_Dashboard from './Component/Doctor/Doctor_Dashboard';
import OrderSummary from './Component/Patient/OrderSummary';
import PatientMedicalRecords from './Component/Patient/PatientMedicalRecords';
import DoctorPatientProfile from './Component/Doctor/DoctorPatientProfile';
import PatientDashboard from './Component/Patient/PatientDashboard';
import DoctorMyPatient from './Component/Doctor/DoctorMyPatient';
import Adminlayout from './Component/Doctor/Adminlayout'
import HomeLayout from './Component/HomeLayout'
import DoctorLogin from './Component/Patient/DoctorLogin'
import DoccureRegister from './Component/Patient/DoctorRegister'
import DoctorForgotPassword from './Component/Patient/DoctorForgotPassword'
import PatientProfilesetting from './Component/Patient/PatientProfilesettings'
import PatientProfilesettings from './Component/Patient/PatientProfilesettings';
import PatientChangePassword from './Component/Patient/PatientChangePassword';
import DoctorChangePassword from './Component/Doctor/DoctorChangePassword';
import AddPrescription from './Component/Doctor/AddPrescription';
import PatientRegister from './Component/Patient/PatientRegister';
import DoctorSidebar from './Component/Doctor/DoctorSidebar';
import ContactUs from './Component/ContactUs/ContactUs';
import AboutUs from './Component/AboutUs/AboutUs';
import Homepage from './Component/Homepage/Homepage';
import Appointmentbookingsuccessfullypopup from './Component/Patient/Appointmentbookingsuccessfullypopup';
import Footer from './Component/Footer';
import Header from './Component/Header';
import DoctorAppointmentByDate from './Component/Doctor/DoctorAppointmentByDate'
// import Profilesettings from './Component/Profilesettings';

window.API_PREFIX = 'https://api.nsdoc.in/'
// window.API_PREFIX = 'http://localhost:8000/'

function App() {


  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 1000)
  // }, [])


  let navigate = useNavigate()
  const [{ IsRegister,Loading }, dispatch] = useStateValue();
  return (
    <>
      <div>
        {
          Loading ?

            <div className='ns_doc_loader_main_division'>

              <ClipLoader
                color={'#15558d'}
                loading={Loading}
                size={50}

              />

            </div>


            :
            (
              <div>
                <Routes path="">

                  <Route path="" element={<HomeLayout />} >
                    <Route path="login" element={<DoctorLogin />} />

                    <Route path="booking" element={<Patient_Booking />} />
                    {/* <Route  path="medicalrecord" element={<PatientMedicalRecords/>} /> */}
                    <Route path="dashboard" element={<PatientDashboard />} />
                    <Route path="changepassword" element={<PatientChangePassword />} />

                    <Route path='profilesetting' element={<PatientProfilesettings />} />
                    <Route path='ordersummary' element={<OrderSummary />} />
                    {/* <Route  path='registerdetail' element={<PatientRegister/>} /> */}


                    <Route path='patientregister' element={<PatientRegister />} />

                    <Route path='contactUs' element={<ContactUs />} />
                    <Route path='aboutus' element={<AboutUs />} />

                    <Route path='' element={<Homepage />} />

                    <Route path='bookingsuccessfully' element={<Appointmentbookingsuccessfullypopup />} />

                  </Route>


                  <Route path="/forgetpassword" element={<><Header /><DoctorForgotPassword /><Footer /></>} />
                  <Route path="/registerdetail" element={<><Header />{!IsRegister ? <PatientRegister /> : null}<Footer /></>} />
                  <Route path="/register" element={<><Header /><DoccureRegister /><Footer /></>} />

                  <Route path="/doctor" element={<Adminlayout />} >


                    <Route path="schedule-timing" element={<Schedule_Timing />} />
                    <Route path="dashboard" element={<Doctor_Dashboard />} />
                    <Route path="mypatient" element={<DoctorMyPatient />} />
                    <Route path="patientprofile/:PID" element={<DoctorPatientProfile />} />
                    <Route path="doctorchangepassword" element={<DoctorChangePassword />} />

                    <Route path="addprescription" element={<AddPrescription />} />
                    <Route path="doctorsidebar" element={<DoctorSidebar />} />
                    <Route path="doctorappointmentbydate" element={<DoctorAppointmentByDate />} />
                    {/* <Route path='loader' element={<Loader/>}/> */}


                  </Route>


                </Routes>
              </div>
            )
        }
      </div>

      {/* <Routes path="">

        <Route path="" element={<HomeLayout />} >
          <Route path="login" element={<DoccureLogin />} />

          <Route path="booking" element={<Patient_Booking />} />
          <Route path="dashboard" element={<PatientDashboard />} />
          <Route path="changepassword" element={<PatientChangePassword />} />

          <Route path='profilesetting' element={<PatientProfilesettings />} />
          <Route path='ordersummary' element={<OrderSummary />} />


          <Route path='patientregister' element={<PatientRegister />} />

          <Route path='contactUs' element={<ContactUs />} />
          <Route path='aboutus' element={<AboutUs />} />

          <Route path='' element={<Homepage />} />

          <Route path='bookingsuccessfully' element={<Appointmentbookingsuccessfullypopup />} />

        </Route>


        <Route path="/forgetpassword" element={<><Header /><DoccureForgotPassword /><Footer /></>} />
        <Route path="/registerdetail" element={<><Header />{!IsRegister ? <PatientRegister /> : null}<Footer /></>} />
        <Route path="/register" element={<><Header /><DoccureRegister /><Footer /></>} />

        <Route path="/doctor" element={<Adminlayout />} >


          <Route path="schedule-timing" element={<Schedule_Timing />} />
          <Route path="dashboard" element={<Doctor_Dashboard />} />
          <Route path="mypatient" element={<DoctorMyPatient />} />
          <Route path="patientprofile/:PID" element={<DoctorPatientProfile />} />
          <Route path="doctorchangepassword" element={<DoctorChangePassword />} />

          <Route path="addprescription" element={<AddPrescription />} />
          <Route path="doctorsidebar" element={<DoctorSidebar />} />
          <Route path="doctorappointmentbydate" element={<DoctorAppointmentByDate />} />


        </Route>


      </Routes> */}
    </>
  );
}

export default App;
