import { useEffect, useState } from 'react';
import headerlogo from '../media/headermainlogo.png';
import headerprofileimage from '../media/image.jpg';
import { useStateValue } from './StateProviders';
// import Patientsidebarresponsive from './Patient/Patientsidebarresponsive';
import './Header.css';
import { Button, Drawer } from 'antd';
import { useNavigate } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import nsdoc from '../media/headermainlogo.png'
import rightdoctor from './homepageimg/rightdoctor.jpg';

import { Slant as Hamburger } from "hamburger-react";


function Simpledoctorheader() {

    const [profile, setProfile] = useState(true)
    const [openMenu, setOpenmenu] = useState(false)
    const [{ IsLogin, IsRegister, DIsLogin }, dispatch] = useStateValue();
    const [balanceState, usebalanceState] = useState(false);
    const [userdata, setuserdata] = useState({});
    const [activeMenu, setActiveMenu] = useState([false, false, false, false])
    let navigate = useNavigate();
    useEffect(() => {
        console.log(">>>Header  IsLogin")
        // dispatch({ type: 'Register' })

        // if (!IsLogin) {
        var Url = window.API_PREFIX + 'admin/doctordetail'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {

                dispatch({ type: 'DLogin' })



                setuserdata({ ...data['data'] })
            }
            else {
                // navigate('/login')
            }
        })
        // }
    }, [DIsLogin])

    // useEffect(() => {
    //     console.log(">>>Header  Register")
    //     // dispatch({ type: 'Register' })

    //     // if (!IsLogin) {
    //     var Url = window.API_PREFIX + 'patient/check_user'
    //     fetch(Url, {
    //         method: 'POST',
    //         headers: {
    //             'Content-type': 'application/json',
    //         },
    //         body: JSON.stringify(
    //             {
    //                 Token: localStorage.getItem('DToken'),
    //             }
    //         ),
    //     }).then(resp => resp.json()).then(data => {
    //         console.log(data)
    //         if (data['status'] === "1") {

    //             if (!data['IsRegister']) {
    //                 dispatch({ type: 'NotRegister' })


    //             }
    //             else {
    //                                 dispatch({ type: 'Register' })
    //                             }



    //             setuserdata({ ...data })
    //         }
    //         else {
    //             // navigate('/login')
    //         }

    //         console.log(IsLogin)
    //         console.log(IsRegister)
    //     })
    //     // }
    // }, [IsRegister])


    // mobile responsive start
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    // mobile responsive start


    const options = [
        {
            userimg: rightdoctor,
            username: 'peril patel',
            apptime: '09:00 AM - 09:30 AM',
            apptdate: '24 MAR 2023'
        },
        {
            userimg: rightdoctor,
            username: 'ramesh patel',
            apptime: '09:30 AM - 10:00 AM',
            apptdate: '25 MAR 2023'
        },
        {
            userimg: rightdoctor,
            username: 'suresh patel',
            apptime: '09:30 AM - 10:00 AM',
            apptdate: '26 MAR 2023'
        },


    ];


    const [anchorEl, setAnchorEl] = useState(null);
    const oopen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const [isFullScrVisible, setFullScrVisible] = useState(false);
    const fullScrStyle = {
        left: isFullScrVisible ? 0 : "",
    };

    const handleMenuClick = () => {
        setFullScrVisible(!isFullScrVisible);
    };

    const [isOpenn, setOpenn] = useState(false);


    return (


        <header className="container-fuild">

            <div className="" id="full-scr" style={fullScrStyle}>
                <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: '1px solid grey' }}>
                    <img src={nsdoc} style={{ width: '60%' }} ></img>
                    <div onClick={handleMenuClick}>
                        <Hamburger
                            toggled={isOpenn}
                            toggle={setOpenn}
                            size={30}
                            color='black'
                        />
                    </div>
                </div>
                <div className='mt-2 sidebar_div_two'>
                    <div onClick={() => {
                        navigate()
                        setFullScrVisible(false)
                    }}>Dashboard</div>

                    <div onClick={() => {
                        navigate()
                        setFullScrVisible(false)
                    }}>My Clients</div>

                    <div onClick={() => {
                        navigate()
                        setFullScrVisible(false)
                    }}>Schdule Timing</div>

                    <div onClick={() => {
                        navigate()
                        setFullScrVisible(false)
                    }}>Change Password</div>
                </div>
            </div>


            <div className=" header_main_division">

            <div className='btn_bar' onClick={handleMenuClick}>
                    <Hamburger
                        toggled={isOpenn}
                        toggle={setOpenn}
                        size={30}
                        color='black'
                    />
                </div>


                <div className="header_logo_main_division">
                    <img className="header_logo_division" src={headerlogo} alt="123" onClick={() => { navigate('/') }} />
                </div>


                {DIsLogin ? <div className='header_center_main_division'>
                    <div className={activeMenu[0] ? 'header_center_division_active' : 'header_center_division'}
                        onClick={() => {
                            setActiveMenu([true, false, false, false])
                            navigate('/doctor/dashboard')
                            setOpen(false)

                        }}>
                        Dashboard
                    </div>

                    <div className={activeMenu[2] ? 'header_center_division_active' : 'header_center_division'}
                        onClick={() => {
                            setActiveMenu([false, false, true, false])
                            navigate('/doctor/mypatient')
                            setOpen(false)

                        }}>
                        My Patient
                    </div>
                    <div className={activeMenu[1] ? 'header_center_division_active' : 'header_center_division'}
                        onClick={() => {
                            setActiveMenu([false, true, false, false])
                            navigate('/doctor/schedule-timing')
                            setOpen(false)

                        }}>
                        Schedule Timing
                    </div>
                    <div className={activeMenu[3] ? 'header_center_division_active' : 'header_center_division'}
                        onClick={() => {
                            setActiveMenu([false, false, false, true])
                            navigate('/doctor/doctorchangepassword')
                            setOpen(false)

                        }
                        }>
                        Change Password
                    </div>
                </div> : null
                }


                {DIsLogin ?
                    <div className="header_contact_profile_main_division">
                        <div className='header_contact_main_division'>
                            <div>
                                <Tooltip title="Notifications">
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={oopen ? 'long-menu' : undefined}
                                        aria-expanded={oopen ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <Badge badgeContent={25} color="primary">
                                            <i class="fa-solid fa-bell "></i>
                                        </Badge>

                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    className='notification_main_div'
                                    disableScrollLock={true}
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={oopen}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            // maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '35ch',
                                            // maxHeight:'5000px'
                                            height: '600px',
                                            marginRight: '10px',
                                            padding: '10px',
                                            borderRadius: '20px'
                                        },
                                    }}

                                >
                                    <div className='doctor_header_notification_title'>Notifications</div>
                                    <div className='doctor_header_new_older_title'>Today</div>
                                    {options.map((option) => (
                                        <MenuItem
                                        //  onClick={handleClose} 

                                        >
                                            <div className='mt-2' >
                                                <div className='doctor_header_notification_card_first_div'>
                                                    <div>
                                                        <img className='doctor_header_notification_patient_image' src={option.userimg}></img>
                                                    </div>
                                                    <div className='mx-2'>
                                                        <div className='doctor_header_notification_patient_name'>{option.username}</div>
                                                        <div className='doctor_header_notification_booking_time'>{option.apptime}</div>
                                                        <div className='doctor_header_notification_booking_date'>{option.apptdate}</div>
                                                    </div>
                                                </div>
                                                <div className='doctor_header_notification_btn_main_div'>
                                                    <button className='btn doctor_header_notification_accept_btn mt-2 '  >
                                                        {/* <i class="fa-solid fa-check"></i> */}
                                                        Accept
                                                    </button>
                                                    <button className='btn doctor_header_notification_cancel_btn mt-2 '>
                                                        {/* <i class="fa-solid fa-xmark"></i> */}
                                                        Reject
                                                    </button>
                                                </div>
                                                <div className='doctor_header_notification_time'>3 Minutes Ago</div>
                                            </div>
                                        </MenuItem>
                                    ))}

                                    <div className='doctor_header_new_older_title'>Previous</div>
                                </Menu>
                            </div>


                        </div>
                        <div onClick={(() => { setProfile(!profile) }
                        )} className='header_profile_main_division'>
                            <div className='header_profile_image_division'>
                                <img className=' header_profile_image' src={userdata['Image']}></img>
                            </div>
                            <div><i class={profile ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up header_profile_arrow_up_icon"}></i></div>
                            {!profile ? <div className='header_profile_division'>
                                <div className='header_profile_division_header'>
                                    <div className='header_profile_header_profile_image_division'><img className='header_profile_header_profile_image' src={userdata['Image']}></img></div>
                                    <div>
                                        <p className='header_profile_header_name'>{userdata['Name']}</p>

                                    </div>
                                </div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        navigate('/doctor/dashboard')
                                    }}
                                ><i class="fa-solid fa-table-columns me-2"></i>  Dashboard</div>

                                <div className='header_profile_list'
                                    onClick={() => {
                                        navigate('/doctor/doctorchangepassword')
                                    }}
                                ><i class="fa-solid fa-lock me-2"></i> Change Password</div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        localStorage.clear()

                                        dispatch({ type: 'DLogout' })

                                        navigate("/doctor")
                                    }}
                                ><i class="fa-solid fa-right-from-bracket me-2"></i> Log Out</div>
                            </div> : null}
                        </div>
                    </div>

                    :

                    <div className='header_login_signup_btn_division'>
                        <button className='btn header_login_signup_btn'
                            onClick={() => {
                                navigate('/doctor')
                                localStorage.clear()
                                dispatch({ type: "Logout" })
                                dispatch({ type: 'DLogout' })

                            }}
                        >login / signup</button>
                    </div>
                }
            </div>
        </header>

    )
}
export default Simpledoctorheader;