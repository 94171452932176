import React from "react";
import "./Footer.css";
import Footer_logo from "../media/headermainlogo.png";

function Footer() {
  return (
    //  Footer-start
    <footer className="footer_main_division">
      {/* <div className="container text-center "> */}
      <div className=" row footer_main_sub_division d-flex">
        <div className=" col-md-6 col-lg-3  footer_logo_division">
          <div className="footer_about_us">
            <h5 className="footer_h5">About US</h5>
          </div>
          <a href="/">
            <img className="footer_logo_img" src={Footer_logo} />
          </a>
          <div className="footer_text">
            <p></p>
          </div>
          <div className="footer_social_icon_division">
            <div>
              <a
                className="footer_for_patient_link"
                href="https://www.facebook.com/NYSHASOLUTIONS/"
              >
                <i className="fa-brands fa-facebook-f footer_social_icon"></i>
              </a>
            </div>
            <div>
              <a
                className="footer_for_patient_link"
                href="https://twitter.com/nyshasolutions"
              >
                <i className="fa-brands fa-twitter footer_social_icon"></i>
              </a>
            </div>
            <div>
              <a
                className="footer_for_patient_link"
                href="https://www.linkedin.com/company/nyshasolutions/?originalSubdomain=in"
              >
                <i className="fa-brands fa-linkedin footer_social_icon"></i>
              </a>
            </div>
            <div>
              <a
                className="footer_for_patient_link"
                href="https://www.instagram.com/nyshasolutions/"
              >
                <i className="fa-brands fa-instagram footer_social_icon"></i>
              </a>
            </div>
            <div>
              <a
                className="footer_for_patient_link"
                href="https://nyshasolutions.com/"
              >
                <i className="fa-sharp fa-solid fa-globe footer_social_icon"></i>
              </a>
            </div>
          </div>
        </div>

        <div className=" col-md-6 col-lg-3 footer_main_for_patient">
          <h5 className="footer_h5">For Patient</h5>
          <div className="footer_main_for_patient_link">
            <i className="fa-solid fa-angles-right footer_for_patient_link_login">
              <label className="footer_for_patient_link_label">
                <a className="footer_for_patient_link_login" href="/Login">
                  Login
                </a>
              </label>
            </i>
            <i className="fa-solid fa-angles-right footer_for_patient_link_register">
              <label className="footer_for_patient_link_label">
                <a
                  className="footer_for_patient_link_register"
                  href="/Register"
                >
                  Register
                </a>
              </label>
            </i>
            <i className="fa-solid fa-angles-right footer_for_patient_link_booking">
              <label className="footer_for_patient_link_label">
                <a
                  className="footer_for_patient_link_booking"
                  href="/Booking"
                >
                  Booking
                </a>
              </label>{" "}
            </i>
            <i className="fa-solid fa-angles-right footer_for_patient_link_patientdashboard">
              <label className="footer_for_patient_link_label">
                <a
                  className="footer_for_patient_link_patientdashboard"
                  href="/Dashboard"
                >
                  Patient Dashboard
                </a>
              </label>
            </i>
          </div>
        </div>

        <div className=" col-md-6 col-lg-3 footer_main_Contact_us">
          <h5 className="footer_h5">Contact US</h5>
          <div className="footer_contact_us_location">
            <i className="fa-solid fa-location-dot footer_contact_us_location_icon"></i>
            <label className="footer_contact_us_label">
              <a
                className="footer_for_patient_link_a"
                href="https://goo.gl/maps/m7hNkQRWy6fZeqvB9"
              >
                FF-9, Sun Rise Shops & Flats, Waghodia Rd, near Vrundavan
                Crossing, Vadodara, 390025
              </a>
            </label>
          </div>
          <div className="footer_contact_us_phone">
            <i className="fa-sharp fa-solid fa-phone-flip footer_contact_us_phone_icon"></i>
            <label className="footer_contact_us_label">
              <a
                className="footer_for_patient_link_a"
                href="tel:(+91)73839 77771"
              >
                (+91)73839 77771
              </a>
            </label>
          </div>
          <div className="footer_contact_us_mail">
            <i className="fa-solid fa-envelope footer_contact_us_mail_icon"></i>
            <label className="footer_contact_us_label">
              <a
                className="footer_for_patient_link_a"
                href="mailto:contact@nyshasolutions.com"
              >
                contact@nyshasolutions.com
              </a>
            </label>
          </div>
        </div>
        <div className=" col-md-6 col-lg-3 footer_map">
          <div className="footer_map_heading">
            <h5 className="footer_h5">Give Us A Visit</h5>
          </div>
          <iframe
            className="footer_map_image"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14765.859168226358!2d73.2477573!3d22.298255!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbbac18ca60b8767e!2sNysha%20Solutions!5e0!3m2!1sen!2sin!4v1668751651502!5m2!1sen!2sin"
            width="350px"
            height="250px"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div className=" footer_copyright">
        <div className="footer_copyright_second_div">
          <div className="footer_copy">
            <div className="copyright_text">
              <label className="footer_copyright_label">
                Copyright <i class="fa-regular fa-copyright"></i> 2022 |
                Developed by{" "}
                <a
                  className="footer_copyright_a"
                  href="https://nyshasolutions.com"
                >
                  Nysha Solutions
                </a>
              </label>
            </div>
          </div>
          <div className="footer_copy_pr">
            <div className="copyright_menu">
              <label className="footer_copyright_policy_label">
                <a className="footer_tc" href="/Terms&Conditions">
                  Terms & Conditions
                </a>{" "}
                |{" "}
                <a className="footer_tc" href="/Privacypolicy">
                  Privacy Policy
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>
    </footer>
    // Footer-end

  );
}

export default Footer;
