import React from 'react';
import '../../App.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination } from "swiper";
import "swiper/css/pagination";
import './Homepage.css';
import shaperound from '../homepageimg/shaperound.png';
import shapecurve from '../homepageimg/shapecurve.png';
import shapedotted from '../homepageimg/shapedotted.png';
import shapelight from '../homepageimg/shapelight.png';
import whiteright from '../homepageimg/whiteright.png';
import womenbanner from '../homepageimg/womenbanner.png';
import womenappoinment from '../homepageimg/womenappoinment.png';
import phonecall from '../homepageimg/phonecall.png';
import sandelblack from '../homepageimg/sandelblack.jpg';
import icon6 from '../homepageimg/icon6.svg';
import asthama from '../homepageimg/asthama.jpg';
import icon3 from '../homepageimg/icon3.svg';
import teethrepair from '../homepageimg/teethrepair.jpg';
import icon4 from '../homepageimg/icon4.svg';
import tube from '../homepageimg/tube.jpg';
import icon2 from '../homepageimg/icon2.svg';
import flatdoctor from '../homepageimg/flatdoctor.jpg';
import dosodoctor from '../homepageimg/dosodoctor.jpg';
import greenright from '../homepageimg/greenright.png';
import smiledoctor from '../homepageimg/smiledoctor.jpg';
import womendoctor from '../homepageimg/womendoctor.jpg';
import icon1 from '../homepageimg/icon1.svg';
import rightdoctor from '../homepageimg/rightdoctor.jpg';
import features01 from '../homepageimg/features01.svg';
import features02 from '../homepageimg/features02.svg';
import features03 from '../homepageimg/features03.svg';
import features04 from '../homepageimg/features04.svg';
import flatwomendoctor from '../homepageimg/flatwomendoctor.png';
import mrimachine from '../homepageimg/mrimachine.jpg';
import physioroom from '../homepageimg/physioroom.jpg';
import womendoctorfull from '../homepageimg/womendoctorfull.jpg';
// import 'aos/dist/aos.css';


function Homepage() {

    const breakPoints = [
        { width: 1440, itemstoshow: 1 }
    ]
    return (
        <div className=''>
            <div className='main_wrapper'>
                <div className='home_one_banner'>
                    <div className='bg_shapes'>
                        <img src={shaperound} className='shape-01' alt='img' />
                        <img src={shapecurve} className='shape-03' alt='img' />
                        <img src={shapelight} className='shape-04' alt='img' />
                        <img src={shapedotted} className='shape-02' alt='img' />

                        <div className='container'>
                            {/* <header className='header'>
                                <div className='nav_bg'>
                                    <nav className='navbar navbar-expand-lg header_nav nav_transparent header_nav_one' >
                                        <div className='navbar_header'>
                                            <a className='navbar_brand logo'>
                                                <img src={bluelogo} className='img-fluid' alt='Logo' />
                                            </a>
                                        </div>
                                        <div className='main_menu_wrapper'>
                                            <div className='menu_header'>
                                                <a className='menu_logo'>
                                                    <img src={bluelogo} className='img-fluid' alt='Logo' />
                                                </a>
                                                <a id='menu_close' className='menu_close'> <i
                                                    className='fas fa-times'></i>
                                                </a>
                                            </div>
                                            <ul className='main_nav black_font grey_font'>
                                                <li className='has_submenu active'>
                                                    <a href='#/'>Home <i className='fas fa-chevron-down'></i></a>
                                                    <ul className='submenu'>
                                                        <li className=''>
                                                            <a >Home</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className='has_submenu '>
                                                    <a href='#/'>Doctors <i className='fas fa-chevron-down'></i></a>
                                                    <ul className='submenu'>
                                                        <li className=''><a >Doctor Dashboard</a></li>
                                                        <li className=''><a >Appointments</a></li>
                                                        <li className=''><a >Schedule Timing</a></li>
                                                        <li className=''><a >Patients List</a></li>
                                                        <li className=''><a >Patients Profile</a></li>
                                                        <li className=''><a >Chat</a></li>
                                                        <li className=''><a >Invoices</a></li>
                                                        <li className=''><a >Profile Settings</a></li>
                                                        <li className=''><a >Reviews</a></li>
                                                        <li className=''><a >Doctor Register</a></li>
                                                        <li className='has_submenu '>
                                                            <a >Blog</a>
                                                            <ul className='submenu'>
                                                                <li className=''><a >Blog</a></li>
                                                                <li><a >Blog view</a></li>
                                                                <li className=''><a >Add Blog</a></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className='has_submenu '>
                                                    <a >Patients <i className='fas fa-chevron-down'></i></a>
                                                    <ul className='submenu'>
                                                        <li className='has_submenu '>
                                                            <a >Doctors</a>
                                                            <ul className='submenu'>
                                                                <li className=''><a >Map Grid</a></li>
                                                                <li className=''><a >Map List</a></li>
                                                                <li className=''><a >Map List 1</a></li>
                                                            </ul>
                                                        </li>
                                                        <li className=''><a >Search Doctor</a></li>
                                                        <li className=''><a >Doctor Profile</a></li>
                                                        <li className=''><a >Booking</a></li>
                                                        <li className=''><a >Checkout</a></li>
                                                        <li className=''><a >Booking Success</a></li>
                                                        <li className=''><a >Patient Dashboard</a></li>
                                                        <li className=''><a >Favourites</a></li>
                                                        <li className=''><a >Chat</a></li>
                                                        <li className=''><a >Profile Settings</a></li>
                                                        <li className=''><a >Change Password</a></li>
                                                    </ul>
                                                </li>
                                                <li className='has_submenu '>
                                                    <a >Pages <i className='fas fa-chevron-down'></i></a>
                                                    <ul className='submenu'>
                                                        <li className=''><a >Voice Call</a></li>
                                                        <li className=''><a >Video Call</a></li>
                                                        <li className=''><a >Search Doctors</a></li>
                                                        <li className=''><a >Calendar</a></li>
                                                        <li className=''><a >Doctor Onboarding</a></li>
                                                        <li className=''><a >Patient Onboarding</a></li>
                                                        <li className=''><a >Components</a></li>
                                                        <li className='has_submenu '>
                                                            <a >Invoices</a>
                                                            <ul className='submenu'>
                                                                <li className=''><a >Invoices</a></li>
                                                                <li className=''><a >Invoice View</a></li>
                                                            </ul>
                                                        </li>
                                                        <li className=''><a >Starter Page</a></li>
                                                        <li className=''><a >Login</a></li>
                                                        <li className=''><a >Register</a></li>
                                                        <li className=''><a >Forgot Password</a></li>
                                                    </ul>
                                                </li>
                                                <li className='has_submenu '>
                                                    <a >Blog <i className='fas fa-chevron-down'></i></a>
                                                    <ul className='submenu'>
                                                        <li className=''><a >Blog List</a></li>
                                                        <li className=''><a >Blog Grid</a></li>
                                                        <li className=''><a >Blog Details</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a >Admin</a>
                                                </li>
                                                <li className='login_link'>
                                                    <a >Login / Signup</a>
                                                </li>
                                            </ul>
                                            <ul className='nav header_navbar_rht right_menu'>
                                                <li className='nav_item'>
                                                    <a className='nav_link login_blue_bg' >Sign In</a>
                                                </li>
                                                <li className='nav_item'>
                                                    <a className='nav_link signup_white_bg' >Sign Up</a>
                                                </li>
                                            </ul>

                                        </div>
                                    </nav>

                                </div>

                            </header> */}
                        </div>


                        <div className='container'>
                            <div className=' homepage_first_part row'>
                                <div className='col-lg-6 col-md-12 part_one_banner_left'>
                                    <div className='banner_info' >
                                        <h2 className='banner_info_search_title'>Search Doctor,</h2>
                                        <h2 ><span className='banner_info_appoinment_title'>Make an Appointment</span></h2>
                                    </div>
                                    <div className='banner_details'>
                                        <h4 className='banner_details_instant_title'>
                                            <img src={whiteright} className='me-2' alt='' /> Instant Operation & Appointment
                                        </h4><br></br>
                                        <h4 className='banner_details_instant_title'><img src={whiteright} className='me-2' alt='' /> 100% Expert Doctors</h4>
                                        <p className='banner_details_paragraph_discover'>Discover the best doctors, clinic & hospital the city nearest to you.</p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 part_one_banner_right'>
                                    <img src={womenbanner} className='img-fluid dr_img' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='appoinment_section' id='appointments'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='appoinment_wrapper'>
                                    <div className='appoinment_box'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <div className='form-group appoinment_location'>
                                                    <i class="fa-solid fa-magnifying-glass appoinment_location_magnify"></i><input type='text' className='form-control'
                                                        placeholder='Search Doctors, Clinics, Etc ...' />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <div className='appoinment_right'>
                                                    <div className='form-group appoinment_location'>
                                                        <i class="fa-solid fa-location-dot appoinment_location_location"></i> <input type='text' className='form-control'
                                                            placeholder='Search location' />
                                                    </div>
                                                    <div className='form-group advance_search'>
                                                        <button type='submit' className='btn appoinment_btn_magnify'>
                                                            <i class="fa-solid fa-magnifying-glass"></i>
                                                        </button>
                                                        <button type='submit' className='btn appoinment_search_btn'>
                                                            <i className='feather-sliders me-2'></i> Advanced Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='appointment_bg'>
                        <div className='custom_contain'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-xl-5 col-lg-4 col-md-12'>
                                        <div className='appointment_left'>
                                            <img src={womenappoinment} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <div className='col-xl-7 col-lg-8 col-md-12'>
                                        <div className='row justify-content-center'>
                                            <div className='col-lg-4 col-md-6 d-flex' >
                                                <div className='appointment_grid w-100'>
                                                    <div className='appointment_icon icon_primary'>
                                                        <i className='fas fa-bed'></i>
                                                    </div>
                                                    <div className='appointment_info'>
                                                        <a className='appointment_link'>Visit a Doctor</a>
                                                        <p>We hire the best specialists to deliver top-notch diagnostic services for
                                                            you.</p>
                                                        <a className='btn appointment_read_btn'>Read More <i
                                                            className='fas fa-arrow-right ms-2'></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-6 d-flex'>
                                                <div className='appointment_grid w-100'>
                                                    <div className='appointment_icon icon_green'>
                                                        <i className='fas fa-tablets'></i>
                                                    </div>
                                                    <div className='appointment_info'>
                                                        <a className='appointment_link'>Find a Pharmacy</a>
                                                        <p>We provide the a wide range of medical services, so every person could have
                                                            the opportunity.</p>
                                                        <a className='btn appointment_read_btn'>Read More <i
                                                            className='fas fa-arrow-right ms-2'></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-6 d-flex'>
                                                <div className='appointment_grid w-100'>
                                                    <div className='appointment_icon icon_red'>
                                                        <i className='fas fa-vial'></i>
                                                    </div>
                                                    <div className='appointment_info'>
                                                        <a className='appointment_link'>Find a Lab</a>
                                                        <p>We use the first-className medical equipment for timely diagnostics of various
                                                            diseases.</p>
                                                        <a className='btn appointment_read_btn'>Coming Soon <i
                                                            className='fas fa-arrow-right ms-2'></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-5 col-md-5 d-flex' data-aos="fade-up">
                                                <div className='appointment_schedule w-100'>
                                                    <h2 className='schedule_title'>Schedule</h2>
                                                    <ul>
                                                        <li><span>Monday - Friday</span> 8.00 - 13.00</li>
                                                        <li><span>Saturday</span> 8.00 - 15.00</li>
                                                        <li><span>Sunday</span> 8.00 - 18.00</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='col-lg-7 col-md-7 d-flex' data-aos="fade-up" >
                                                <div className='appointment_request w-100'>
                                                    <h2 className='schedule_title text-white'>REQUEST AN APPOINTMENT</h2>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='appointment_img_holder'>
                                                            <img src={phonecall} alt='' />
                                                        </div>
                                                        <div className='appointment_info_holder'>
                                                            <p>Call us on</p>
                                                            <h2>+1 (208) 567 0707</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>






                <section className='service_section'>
                    <div className='container' data-aos="fade-up">
                        <div className='section_header text-center'>
                            <h2 className='color-primary'>High Quality Service for you</h2>
                            <p className='sub_title color-grey'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6'>
                                <div className='service_grid'>
                                    <div className='effect_oscar'>
                                        <img src={sandelblack} className='img-fluid services_img' alt='' />
                                        <div className='services_caption'>
                                            <div className='services_inner'>
                                                <div className='service_grid_icon'>
                                                    <img src={icon6} className='img-fluid' alt='' />
                                                </div>
                                                <p>Orthopedic</p>
                                                <a className='service_link'>
                                                    <i className='fas fa-arrow-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6' >
                                <div className='service_grid'>
                                    <div className='effect_oscar'>
                                        <img src={asthama} className='img-fluid services_img' alt='' />
                                        <div className='services_caption'>
                                            <div className='services_inner'>
                                                <div className='service_grid_icon'>
                                                    <img src={icon3} className='img-fluid' alt='' />
                                                </div>
                                                <p>Cardiologist</p>
                                                <a className='service_link'>
                                                    <i className='fas fa-arrow-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6'>
                                <div className='service_grid'>
                                    <div className='effect_oscar'>
                                        <img src={teethrepair} className='img-fluid services_img' alt='' />
                                        <div className='services_caption'>
                                            <div className='services_inner'>
                                                <div className='service_grid_icon'>
                                                    <img src={icon4} className='img-fluid' alt='' />
                                                </div>
                                                <p>Dentist</p>
                                                <a className='service_link'>
                                                    <i className='fas fa-arrow-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 ' >
                                <div className='service_grid'>
                                    <div className='effect_oscar'>
                                        <img src={tube} className='img-fluid services_img' alt='' />
                                        <div className='services_caption'>
                                            <div className='services_inner'>
                                                <div className='service_grid_icon'>
                                                    <img src={icon2} className='img-fluid' alt='' />
                                                </div>
                                                <p>Neurology</p>
                                                <a className='service_link'>
                                                    <i className='fas fa-arrow-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>








                <div className='container'>
                    <section className='doctor_section' data-aos="fade-up">
                        <div className='doctor_right_img'>
                            <img src={flatdoctor} className='img-fluid' alt='' />
                        </div>
                        {/* <div className='container'> */}
                            <div className='section_header text-center'>
                                <h2 className='color-primary'>Book Our Doctor</h2>
                                <p className='sub_title color-grey'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='features_section_slider'>
                                        <Swiper
                                            slidesPerView={4}
                                            spaceBetween={30}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Pagination]}
                                            breakpoints={{
                                                1440: {
                                                    slidesPerView: 4,
                                                },
                                                768: {
                                                    slidesPerView: 3,
                                                },
                                                425: {
                                                    slidesPerView: 1,
                                                },
                                                375: {
                                                    slidesPerView: 1,
                                                },
                                                320: {
                                                    slidesPerView: 1,
                                                }
                                            }}
                                            className="mySwiper">


                                            <SwiperSlide>
                                                <div className='doctor_profile_widget'>
                                                    <a >
                                                        <img src={dosodoctor} className='img-fluid book_doctor' alt='' />
                                                    </a>
                                                    <div className='pro_content'>
                                                        <div className='provider_info'>
                                                            <div className='pro_icon'>
                                                                <img src={icon2} className='img-fluid' alt='' />
                                                            </div>
                                                            <h3 className='mb-1'>
                                                                <a >Edward Willey
                                                                    <img src={greenright} alt='' />
                                                                </a>
                                                            </h3>
                                                            <p>Neurologist Specialist</p>
                                                            <div>
                                                                <div className='rating'>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star'></i>
                                                                    <span className='d-inline-block average-rating'>4.9 ( 82 )</span>
                                                                </div>
                                                            </div>
                                                            <div className='content_info'>
                                                                <div className='social_info'>
                                                                    <a  ><i className='fab fa-facebook hi_icon'></i></a>
                                                                    <a  ><i className='fab fa-linkedin hi_icon'></i></a>
                                                                    <a  ><i className='fab fa-instagram hi_icon'></i></a>
                                                                    <a ><i className='fab fa-twitter hi_icon'></i></a>
                                                                </div>
                                                                <div className='booking_btn'>
                                                                    <a href='' className='btn book_btn'>
                                                                        Book Appointment
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='doctor_profile_widget'>
                                                    <a >
                                                        <img src={smiledoctor} className='img-fluid book_doctor' alt='' />
                                                    </a>
                                                    <div className='pro_content'>
                                                        <div className='provider_info'>
                                                            <div className='pro_icon'>
                                                                <img src={icon3} className='img-fluid' alt='' />
                                                            </div>
                                                            <h3 className='mb-1'>
                                                                <a >Deborah Angel
                                                                    <img src={greenright} alt='' />
                                                                </a>
                                                            </h3>
                                                            <p>Cardiology Specialist</p>
                                                            <div>
                                                                <div className='rating'>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star'></i>
                                                                    <span className='d-inline-block average-rating'>4.9 ( 82 )</span>
                                                                </div>
                                                            </div>
                                                            <div className='content_info'>
                                                                <div className='social_info'>
                                                                    <a ><i className='fab fa-facebook hi_icon'></i></a>
                                                                    <a><i className='fab fa-linkedin hi_icon'></i></a>
                                                                    <a  ><i className='fab fa-instagram hi_icon'></i></a>
                                                                    <a  ><i className='fab fa-twitter hi_icon'></i></a>
                                                                </div>
                                                                <div className='booking_btn'>
                                                                    <a href='' className=' btn book_btn'>
                                                                        Book Appointment
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='doctor_profile_widget'>
                                                    <a >
                                                        <img src={womendoctor} className='img-fluid book_doctor' alt='' />
                                                    </a>
                                                    <div className='pro_content'>
                                                        <div className='provider_info'>
                                                            <div className='pro_icon'>
                                                                <img src={icon1} className='img-fluid' alt='' />
                                                            </div>
                                                            <h3 className='mb-1'>
                                                                <a >Merry Anderson
                                                                    <img src={greenright} alt='' />
                                                                </a>
                                                            </h3>
                                                            <p>Gastrologic Specialist</p>
                                                            <div>
                                                                <div className='rating'>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star'></i>
                                                                    <span className='d-inline-block average-rating'>4.9 ( 82 )</span>
                                                                </div>
                                                            </div>
                                                            <div className='content_info'>
                                                                <div className='social_info'>
                                                                    <a ><i className='fab fa-facebook hi_icon'></i></a>
                                                                    <a ><i className='fab fa-linkedin hi_icon'></i></a>
                                                                    <a ><i className='fab fa-instagram hi_icon'></i></a>
                                                                    <a ><i className='fab fa-twitter hi_icon'></i></a>
                                                                </div>
                                                                <div className='booking_btn'>
                                                                    <a href='' className='btn book_btn'>
                                                                        Book Appointment
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='doctor_profile_widget'>
                                                    <a >
                                                        <img src={rightdoctor} className='img-fluid book_doctor' alt='' />
                                                    </a>
                                                    <div className='pro_content'>
                                                        <div className='provider_info'>
                                                            <div className='pro_icon'>
                                                                <img src={icon4} className='img-fluid' alt='' />
                                                            </div>
                                                            <h3 className='mb-1'>
                                                                <a  >David Blackwell
                                                                    <img src={greenright} alt='' />
                                                                </a>
                                                            </h3>
                                                            <p>Dental Specialist</p>
                                                            <div>
                                                                <div className='rating'>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star'></i>
                                                                    <span className='d-inline-block average-rating'>4.9 ( 82 )</span>
                                                                </div>
                                                            </div>
                                                            <div className='content_info'>
                                                                <div className='social_info'>
                                                                    <a ><i className='fab fa-facebook hi_icon'></i></a>
                                                                    <a ><i className='fab fa-linkedin hi_icon'></i></a>
                                                                    <a ><i className='fab fa-instagram hi_icon'></i></a>
                                                                    <a ><i className='fab fa-twitter hi_icon'></i></a>
                                                                </div>
                                                                <div className='booking_btn'>
                                                                    <a href='' className='btn book_btn'>
                                                                        Book Appointment
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='doctor_profile_widget'>
                                                    <a >
                                                        <img src={dosodoctor} className='img-fluid book_doctor' alt='' />
                                                    </a>
                                                    <div className='pro_content'>
                                                        <div className='provider_info'>
                                                            <div className='pro_icon'>
                                                                <img src={icon2} className='img-fluid' alt='' />
                                                            </div>
                                                            <h3 className='mb-1'>
                                                                <a >Edward Willey
                                                                    <img src={greenright} alt='' />
                                                                </a>
                                                            </h3>
                                                            <p>Dermatologist</p>
                                                            <div>
                                                                <div className='rating'>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star filled'></i>
                                                                    <i className='fas fa-star'></i>
                                                                    <span className='d-inline-block average-rating'>4.9 ( 82 )</span>
                                                                </div>
                                                            </div>
                                                            <div className='content_info'>
                                                                <div className='social_info'>
                                                                    <a ><i className='fab fa-facebook hi_icon'></i></a>
                                                                    <a ><i className='fab fa-linkedin hi_icon'></i></a>
                                                                    <a ><i className='fab fa-instagram hi_icon'></i></a>
                                                                    <a ><i className='fab fa-twitter hi_icon'></i></a>
                                                                </div>
                                                                <div className='booking_btn'>
                                                                    <a href='' className='btn book_btn'>
                                                                        Book Appointment
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 text-end'>
                                    <div className='slick_view_btn me-2'>
                                        <a className='btn'>View More <i className='fas fa-arrow-right ms-2'></i></a>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </section>
                </div>














                <section className='features-section' data-aos="fade-up">
                    <div className='container'>
                        <div className='section_header text-center '>
                            <h2 className='color-primary'>Availabe Features in Our Clinic</h2>
                            <p className='sub_title color-grey'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className=' swiper_main_div' >
                                    <Swiper
                                        slidesPerView={4}
                                        spaceBetween={30}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        breakpoints={{
                                            1440: {
                                                slidesPerView: 4,
                                            },
                                            768: {
                                                slidesPerView: 3,
                                            },
                                            425: {
                                                slidesPerView: 1,
                                            },
                                            375: {
                                                slidesPerView: 1,
                                            },
                                            320: {
                                                slidesPerView: 1,
                                            }
                                        }}
                                        modules={[Pagination]}
                                        className="mySwiper">


                                        <SwiperSlide>
                                            <div className='features_grid hvr_bounce_to_bottom'>
                                                <div className='features_cricle'>
                                                    <div className='features_round'>
                                                        <img src={features01} className='img-fluid' alt='' />
                                                    </div>
                                                </div>
                                                <h4>Operation</h4>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='features_grid hvr_bounce_to_bottom'>
                                                <div className='features_cricle'>
                                                    <div className='features_round'>
                                                        <img src={features02} className='img-fluid' alt='' />
                                                    </div>
                                                </div>
                                                <h4>Medical</h4>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='features_grid hvr_bounce_to_bottom'>
                                                <div className='features_cricle'>
                                                    <div className='features_round'>
                                                        <img src={features03} className='img-fluid' alt='' />
                                                    </div>
                                                </div>
                                                <h4>Patient Ward</h4>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='features_grid hvr_bounce_to_bottom'>
                                                <div className='features_cricle'>
                                                    <div className='features_round'>
                                                        <img src={features04} className='img-fluid' alt='' />
                                                    </div>
                                                </div>
                                                <h4>Laboratory</h4>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='features_grid hvr_bounce_to_bottom'>
                                                <div className='features_cricle'>
                                                    <div className='features_round'>
                                                        <img src={features01} className='img-fluid' alt='' />
                                                    </div>
                                                </div>
                                                <h4>Operation</h4>
                                            </div>
                                        </SwiperSlide>

                                    </Swiper>

                                </div>


                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 text-end'>
                                <div className='slick_view_btn mt-5'>
                                    <a className='btn'>
                                        View More <i className='fas fa-arrow-right ms-2'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <section className='news_letter_bg' >
                    <div className='container' data-aos="flip-up">
                        <div className='news_bg' >
                            <div className='row'>
                                <div className='col-lg-8 col-md-12 news_left' >
                                    <div>
                                        <h2>Grab Our Newsletter</h2>
                                        <p>To receive latest offers and discounts from the shop.</p>
                                    </div>
                                    <div>
                                        <form>
                                            <div className='form-group'>
                                                <input type='text' className='form-control me-2' placeholder='Enter Your Email Address' />
                                                <button type='submit' className='btn'>Subscribe</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-12' >
                                    <div className='news_img'>
                                        <img src={flatwomendoctor} className='img-fluid' alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>










                <section className='news_section'>
                    <div className='container' data-aos="flip-up"  >
                        <div className='section_header text-center' >
                            <h2 className='color-primary'>Let's See Our Latest News</h2>
                            <p className='sub_title color-grey'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-lg-4 col-md-6 d-flex'>
                                <div className='grid_news w-100'>
                                    <div className='news_image'>
                                        <a>
                                            <img className='img-fluid' src={mrimachine} alt='News' />
                                        </a>
                                    </div>
                                    <div className='news_content'>
                                        <ul>
                                            <li>
                                                <div className='news_date'>
                                                    <a >
                                                        <i class="fa-solid fa-calendar me-2"></i>
                                                        <span>4 Dec 2021</span>
                                                    </a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='news_date'>
                                                    <a className='feather_calendar'>
                                                        <i class="fa-solid fa-tag me-2"></i>
                                                        <span>Heath Care</span>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                        <h3 className='news_title'>
                                            <a >How to handle patient body in MRI</a>
                                        </h3>
                                        <p>
                                            <a >Read News</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 d-flex ' >
                                <div className='grid_news w-100'>
                                    <div className='news_image'>
                                        <a >
                                            <img className='img-fluid' src={physioroom} alt='News ' />
                                        </a>
                                    </div>
                                    <div className='news_content'>
                                        <ul>
                                            <li>
                                                <div className='news_date'>
                                                    <a >
                                                        <i class="fa-solid fa-calendar me-2"></i>
                                                        <span>10 Dec 2021</span>
                                                    </a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='news_date'>
                                                    <a className='feather_calendar'>
                                                        <i class="fa-solid fa-tag me-2"></i>
                                                        <span>Surgery</span>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                        <h3 className='news_title'>
                                            <a >Doccure - Making your clinic painless visit?</a>
                                        </h3>
                                        <p>
                                            <a >Read News</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 d-flex'>
                                <div className='grid_news w-100'>
                                    <div className='news_image'>
                                        <a >
                                            <img className='img-fluid' src={womendoctorfull} alt='News' />
                                        </a>
                                    </div>
                                    <div className='news_content'>
                                        <ul>
                                            <li>
                                                <div className='news_date'>
                                                    <a >
                                                        <i class="fa-solid fa-calendar me-2"></i>
                                                        <span>15 Dec 2021</span>
                                                    </a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='news_date'>
                                                    <a className='feather_calendar'>
                                                        <i class="fa-solid fa-tag me-2"></i>
                                                        <span>General</span>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                        <h3 className='news_title'>
                                            <a >Benefits of consulting with an Online Doctor</a>
                                        </h3>
                                        <p>
                                            <a >Read News</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='blog_details_btn text-center' data-aos="fade-up" data-aos-delay="100">
                            <a className='btn view_more'>View More <i className='fas fa-arrow-right ms-2'></i></a>
                        </div>
                    </div>
                </section>



            </div>
        </div>
    )
}
export default Homepage;