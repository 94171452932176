// import { Header } from "antd/lib/layout/layout";
import { useEffect } from "react";
import { Outlet } from "react-router-dom"
import Footer from "../Footer";
import Simpledoctorheader from '../Simpledoctorheader';
import { useStateValue } from "../StateProviders";
import DoctorLogin from "./DoctorLogin";
export default function Adminlayout()
{

    const [{DIsLogin},dispatch] = useStateValue();
useEffect(()=>{
if(localStorage.getItem('DToken')!==null)
{
    dispatch({
        type:'DLogin'
    })
}
else
{
    dispatch({
        type:'DLogout'
    })
}

},[])
    return (<>
   {/* <Header /> */}
   <Simpledoctorheader/>
    {DIsLogin?
    <Outlet/>:<DoctorLogin/>}
    <Footer/>
    </>)
}