import "./DoctorLogin.css";
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProviders";
import doccureloginimg from "../../media/login_img.png";
import { useEffect, useState } from "react";
import "./Sidebar.css";

function DoctorLogin() {
  let navigate = useNavigate();

  useEffect(() => {
    if (DIsLogin) {
      navigate("/doctor/dashboard");
    }
  }, []);
  const [{ DIsLogin }, dispatch] = useStateValue();
  const [email, setEmail] = useState();
  const [Password, setPassword] = useState();

  const [Inputtype, setInputtype] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);

  return (
    <div className="container">
      <div className="doccure_login_main_division">
        <div className="doccure_login_main_first_division">
          <div className="d-flex justify-content-center">
            <div className="doccure_login_img_division">
              <img className="doccure_login_img" src={doccureloginimg} />
            </div>
          </div>
        </div>

        <div className="doccure_login_main_second_division">
          <div className="doccure_login_title_name">Doctor Login</div>

          <div class="doccure_login_email_field_division form-floating mt-3">
            <input
              type="email"
              class="doccure_login_email_field1 form-control"
              id="floatingInput"
              placeholder="name@example.com"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <label for="floatingInput">Email</label>
          </div>

          <div className="doccure_login_password_field_division">
          <div class="doccure_login_email_field_division1 form-floating">
            <input
              type={Inputtype}
              class="doccure_login_email_field form-control"
              id="floatingPassword"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  var Url = window.API_PREFIX + "admin/adminlogin";
                  fetch(Url, {
                    method: "POST",
                    headers: {
                      "Content-type": "application/json",
                    },
                    body: JSON.stringify({
                      Email: email,
                      Password: e.target.value,
                    }),
                  })
                    .then((resp) => resp.json())
                    .then((data) => {
                      console.log(data);
                      if (data["status"] === "1") {
                        localStorage.setItem("DToken", data["Token"]);
                        dispatch({ type: "DLogin" });
                        navigate("/doctor/dashboard");
                        window.location.reload();
                      } else {
                        alert(data["message"]);
                      }
                    });
                }
              }}
            />
            <label for="floatingPassword">Password</label>
          </div>
          <div className="doccure_login_password_eyes_icon">
              <i
                className=" eye_icon"
                onClick={(e) => {
                  if (Inputtype == "password") {
                    setInputtype("text");
                    setButtonName(<i class="fa-solid fa-eye-slash"></i>);
                  } else {
                    setInputtype("password");
                    setButtonName(<i class="fa-solid fa-eye"></i>);
                  }

                  e.preventDefault();
                }}
              >
                {ButtonName}
              </i>
            </div>
          </div>

          <div
            className="doccure_login_forgot_password_division"
            onClick={() => {
              navigate("/doctor/forgetpassword");
            }}
          >
            Forgot Password ?
          </div>
          <button
            className="doccure_login_btn_login_division"
          



            onClick={() => {
              var Url = window.API_PREFIX + "admin/adminlogin";
              fetch(Url, {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  Email: email,
                  Password: Password,
                }),
              })
                .then((resp) => resp.json())
                .then((data) => {
                  console.log(data);
                  if (data["status"] === "1") {
                    localStorage.setItem("DToken", data["Token"]);
                    dispatch({ type: "DLogin" });
                    navigate("/doctor/dashboard");
                    window.location.reload();
                  } else {
                    alert(data["message"]);
                  }
                });
            }}
          >
            Login
          </button>

          {/* <div className='doccure_login_new_account_title_division'>
                        <div className='doccure_login_new_account_title'>Don't have an account?</div>
                        <div className='doccure_login_new_account_title_register'
                        onClick={()=>{
                            navigate('/doctor/register')
                        }}
                        >Register</div>
                    </div> */}
        </div>
      </div>
    </div>
  );
}

export default DoctorLogin;
