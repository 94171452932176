import './AboutUs.css';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import aboutusfeatureimg10 from '../../media/features/feature-10.jpg';
import aboutusfeatureimg07 from '../../media/features/feature-07.jpg';
import aboutusfeatureimg09 from '../../media/features/feature-09.jpg';
import aboutusfeatureimg08 from '../../media/features/feature-08.jpg';
import aboutusfeatureimg11 from '../../media/features/feature-11.jpg';
import aboutusfeatureimg12 from '../../media/features/feature-12.jpg';
import aboutusfeatureicon01 from '../../media/icons/vect-01.png';
import aboutusfeatureicon02 from '../../media/icons/vect-02.png';
import aboutusfeatureicon03 from '../../media/icons/vect-03.png';
import aboutusspecificationimg01 from '../../media/specialities/specialities-01.png';
import aboutusspecificationimg02 from '../../media/specialities/specialities-02.png';
import aboutusspecificationimg03 from '../../media/specialities/specialities-03.png';
import aboutusspecificationimg04 from '../../media/specialities/specialities-04.png';
import aboutusspecificationimg05 from '../../media/specialities/specialities-05.png';
import aboutuspatientimg1 from '../../media/patients/patient1.jpg';
import aboutuspatientimg2 from '../../media/patients/patient2.jpg';
import aboutuspatientimg3 from '../../media/patients/patient3.jpg';
import aboutuspatientimg4 from '../../media/patients/patient4.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination } from "swiper";

function AboutUs() {
    let navigate = useNavigate();

    return (
        <>

            <div class="aboutus-main-wrapper">
                {/* breadcrumbs-start */}
                {/* <div className='aboutus_breadcrumb_main_division'>
                    <div className='aboutus_breadcrumb_division'>
                        <p className='aboutus_breadcrumb_title'

                            onClick={() => { navigate("/") }}
                        >home</p>
                        <p className='aboutus_breadcrumb_title1'>/</p>
                        <p className='aboutus_breadcrumb_title2'
                            onClick={() => { navigate("/aboutus") }}
                        >About Us</p>
                    </div>
                    <div>
                        <h1 className='aboutus_breadcrumb_title_main'>about us</h1>
                    </div>
                </div> */}
                {/* breadcrumbs-end */}
<div className='w3-container w3-center w3-animate-bottom'>
                <section class="aboutus-about-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                {/* <a href="#" class="aboutus-about-titile mb-4">About Doccure</a> */}
                                <h3 class="mb-4">Company Profile</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porta luctus est interdum pretium. Fusce id tortor fringilla, suscipit turpis ac, varius ex. Cras vel metus ligula. Nam enim ligula, bibendum a iaculis ut, cursus id augue. Proin vitae purus id tortor vehicula scelerisque non in libero.</p>
                                <p class="mb-0">Nulla non turpis sit amet purus pharetra sollicitudin. Proin rutrum urna ut suscipit ullamcorper. Proin sapien felis, dignissim non finibus eget, luctus vel purus. Pellentesque efficitur congue orci ornare accumsan. Nullam ultrices libero vel imperdiet scelerisque. Donec vel mauris eros.</p>
                            </div>
                            <div class="col-md-6">
                            </div>
                        </div>
                    </div>
                </section>

                <section class="aboutus-select-category">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-md-6 aboutus-category-col d-flex mt-4">
                                <div class="aboutus-category-subox pb-0 d-flex flex-wrap w-100">
                                    <h4>Visit a Doctor</h4>
                                    <p>We hire the best specialists to deliver top-notch diagnostic services for you.</p>
                                    <div class="aboutus-subox-img">
                                        <div class="aboutus-subox-circle">
                                            <img src={aboutusfeatureicon01} alt="" width="42" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 aboutus-category-col d-flex mt-4">
                                <div class="aboutus-category-subox pb-0 d-flex flex-wrap w-100">
                                    <h4>Find a Pharmacy</h4>
                                    <p>We provide the a wide range of medical services, so every person could have the opportunity.</p>
                                    <div class="aboutus-subox-img">
                                        <div class="aboutus-subox-circle">
                                            <img src={aboutusfeatureicon02} alt="" width="42" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 aboutus-category-col d-flex mt-4">
                                <div class="aboutus-category-subox pb-0 d-flex flex-wrap w-100">
                                    <h4>Find a Lab</h4>
                                    <p>We use the first-class medical equipment for timely diagnostics of various diseases.</p>
                                    <div class="aboutus-subox-img">
                                        <div class="aboutus-subox-circle">
                                            <img src={aboutusfeatureicon03} alt="" width="42" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section aboutus-section-featurebox">
                    <div class="container">
                        <div class="aboutus-section-header text-center">
                            <h2>Available Features in Our Clinic</h2>
                            <p class="aboutus-sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="aboutus-feature-col-list">
                                <div class="aboutus-feature-col">
                                    <div class="aboutus-feature-subox d-flex flex-wrap justify-content-center">
                                        <img src={aboutusfeatureimg07} class="aboutus-img-fluid" alt="Features" />
                                        <h4>Operation</h4>
                                    </div>
                                </div>
                                <div class="aboutus-feature-col">
                                    <div class="aboutus-feature-subox d-flex flex-wrap justify-content-center">
                                        <img src={aboutusfeatureimg09} class="aboutus-img-fluid" alt="Features" />
                                        <h4>Medical</h4>
                                    </div>
                                </div>
                                <div class="aboutus-feature-col">
                                    <div class="aboutus-feature-subox d-flex flex-wrap justify-content-center">
                                        <img src={aboutusfeatureimg08} class="aboutus-img-fluid" alt="Features" />
                                        <h4>Patient Ward</h4>
                                    </div>
                                </div>
                                <div class="aboutus-feature-col">
                                    <div class="aboutus-feature-subox d-flex flex-wrap justify-content-center">
                                        <img src={aboutusfeatureimg10} class="aboutus-img-fluid" alt="Features" />
                                        <h4>Test Room</h4>
                                    </div>
                                </div>
                                <div class="aboutus-feature-col">
                                    <div class="aboutus-feature-subox d-flex flex-wrap justify-content-center">
                                        <img src={aboutusfeatureimg11} class="aboutus-img-fluid" alt="Features" />
                                        <h4>ICU</h4>
                                    </div>
                                </div>
                                <div class="aboutus-feature-col">
                                    <div class="aboutus-feature-subox d-flex flex-wrap justify-content-center">
                                        <img src={aboutusfeatureimg12} class="aboutus-img-fluid" alt="Features" />
                                        <h4>Laboratory</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section aboutus-section-specialities">
                    <div class="container-fluid">
                        <div class="aboutus-section-header text-center">
                            <h2>Clinic and Specialities</h2>
                            <p class="aboutus-sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="aboutus-specialities-slider slider d-flex justify-content-center col-md-9">
                                <Swiper
                                    spaceBetween={30}
                                    slidesPerView={6}
                                    breakpoints={{
                                        1440: {
                                            slidesPerView: 7,
                                        },
                                        1024: {
                                            slidesPerView: 4,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                        },
                                        430: {
                                            slidesPerView: 2,
                                        },
                                        375:{
                                            slidesPerView: 2,
                                        },
                                        320:{
                                            slidesPerView: 1,
                                        }
                                    }}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                >

                                    <SwiperSlide>
                                        <div class="aboutus-speicality-item text-center me-3">
                                            <div class="aboutus-speicality-img">
                                                <img src={aboutusspecificationimg01} class="aboutus-img-fluid" alt="Speciality" />
                                                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                            </div>
                                            <p>Urology</p>
                                        </div>
                                    </SwiperSlide >



                                    <SwiperSlide>
                                        <div class="aboutus-speicality-item text-center me-3">
                                            <div class="aboutus-speicality-img">
                                                <img src={aboutusspecificationimg02} class="aboutus-img-fluid" alt="Speciality" />
                                                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                            </div>
                                            <p>Neurology</p>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide>
                                        <div class="aboutus-speicality-item text-center me-3">
                                            <div class="aboutus-speicality-img">
                                                <img src={aboutusspecificationimg03} class="aboutus-img-fluid" alt="Speciality" />
                                                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                            </div>
                                            <p>Orthopedic</p>
                                        </div>
                                    </SwiperSlide>




                                    <SwiperSlide>
                                        <div class="aboutus-speicality-item text-center me-3">
                                            <div class="aboutus-speicality-img">
                                                <img src={aboutusspecificationimg04} class="aboutus-img-fluid" alt="Speciality" />
                                                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                            </div>
                                            <p>Cardiologist</p>
                                        </div>
                                    </SwiperSlide>



                                    <SwiperSlide>
                                        <div class="aboutus-speicality-item text-center me-3">
                                            <div class="aboutus-speicality-img">
                                                <img src={aboutusspecificationimg05} class="aboutus-img-fluid" alt="Speciality" />
                                                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                            </div>
                                            <p>Dentist</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="aboutus-speicality-item text-center me-3">
                                            <div class="aboutus-speicality-img">
                                                <img src={aboutusspecificationimg05} class="aboutus-img-fluid" alt="Speciality" />
                                                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                            </div>
                                            <p>Dentist</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="aboutus-speicality-item text-center me-3">
                                            <div class="aboutus-speicality-img">
                                                <img src={aboutusspecificationimg05} class="aboutus-img-fluid" alt="Speciality" />
                                                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                            </div>
                                            <p>Dentist</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="aboutus-speicality-item text-center me-3">
                                            <div class="aboutus-speicality-img">
                                                <img src={aboutusspecificationimg05} class="aboutus-img-fluid" alt="Speciality" />
                                                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                            </div>
                                            <p>Dentist</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="aboutus-speicality-item text-center me-3">
                                            <div class="aboutus-speicality-img">
                                                <img src={aboutusspecificationimg05} class="aboutus-img-fluid" alt="Speciality" />
                                                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                            </div>
                                            <p>Dentist</p>
                                        </div>
                                    </SwiperSlide>

                                </Swiper>















                            </div>
                        </div>
                    </div>
                </section>


                <section class="section aboutus-section-testimonial">
                    <div class="container">
                        <div class="aboutus-section-header text-center mb-4">
                            <h2>Testimonials</h2>
                            <p class="aboutus-sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12">

                                <div class="aboutus-testimonial-slider slider d-flex">

                                    <Swiper
                                        spaceBetween={10}
                                        slidesPerView={3}
                                        breakpoints={{
                                            1440: {
                                                slidesPerView: 3,
                                            },
                                            425: {
                                                slidesPerView: 2,
                                            },
                                            375:{
                                                slidesPerView: 1,
                                            },
                                            320:{
                                                slidesPerView: 1,
                                            }
                                        }}
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        <SwiperSlide>
                                            <div class="aboutus-testimonial-item text-center me-3">
                                                <div class="aboutus-testimonial-img">
                                                    <img src={aboutuspatientimg1} class="img-fluid" alt="Speciality" />
                                                </div>
                                                <div class="aboutus-testimonial-content">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    <p class="aboutus-user-name">- Jennifer Robinson</p>
                                                    <p class="aboutus-user-location mb-0">Leland, USA</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div class="aboutus-testimonial-item text-center me-3">
                                                <div class="aboutus-testimonial-img">
                                                    <img src={aboutuspatientimg2} class="img-fluid" alt="Speciality" />
                                                </div>
                                                <div class="aboutus-testimonial-content">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    <p class="aboutus-user-name">- Denise Stevens</p>
                                                    <p class="aboutus-user-location mb-0">Abington, USA</p>
                                                </div>
                                            </div>  </SwiperSlide>

                                        <SwiperSlide>
                                            <div class="aboutus-testimonial-item text-center me-3">
                                                <div class="aboutus-testimonial-img">
                                                    <img src={aboutuspatientimg3} class="img-fluid" alt="Speciality" />
                                                </div>
                                                <div class="aboutus-testimonial-content">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    <p class="aboutus-user-name">- Charles Ortega</p>
                                                    <p class="aboutus-user-location mb-0">El Paso, USA</p>
                                                </div>
                                            </div>  </SwiperSlide>

                                        <SwiperSlide>
                                            <div class="aboutus-testimonial-item text-center me-3">
                                                <div class="aboutus-testimonial-img">
                                                    <img src={aboutuspatientimg4} class="img-fluid" alt="" />
                                                </div>
                                                <div class="aboutus-testimonial-content">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    <p class="aboutus-user-name">- Jennifer Robinson</p>
                                                    <p class="aboutus-user-location mb-0">Leland, USA</p>
                                                </div>
                                            </div>  </SwiperSlide>
                                    </Swiper>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                </div>
            </div>

        </>
    )
}

export default AboutUs;