import React from "react";
import '../../App.css';
import "./DoctorChangePassword.css";
import { useState } from "react";
import "./Sidebar.css";
import DoctorSidebar from "./DoctorSidebar";
import Doctorsidebarresponsive from "./Doctorsidebarresponsive";
import { useStateValue } from '../StateProviders';

export default function DoctorChangePassword() {

  
  const [{Loading},dispatch] = useStateValue();

  const [oldpassword, setoldpassword] = useState(0);
  const [newpassword, setnewpassword] = useState(0);
  const [confirmpassword, setconfirmpassword] = useState(0);

  const [Inputtype, setInputtype] = useState("password");
  const [Inputtypen, setInputtypen] = useState("password");
  const [Inputtypeco, setInputtypeco] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);
  const [ButtonNamen, setButtonNamen] = useState(
    <i class="fa-solid fa-eye"></i>
  );
  const [ButtonNameco, setButtonNameco] = useState(
    <i class="fa-solid fa-eye"></i>
  );
  return (

      <>
        {/* breadcrumbs-start */}
        <nav className="doctor_change_password_breadcrumb_main_division">
          <div className="doctor_change_password_breadcrumb_division">
            <p className="doctor_change_password_breadcrumb_title">home</p>
            <p className="doctor_change_password_breadcrumb_title1">/</p>
            <p className="doctor_change_password_breadcrumb_title2">
              Profile Setings
            </p>
          </div>
          <div>
            <h1 className="doctor_change_password_breadcrumb_title_main">
              Profile Setings
            </h1>
          </div>
        </nav>
        {/* breadcrumbs-end */}
        <div className="container-fuild">
          <div className="slidebar_and_component">
            <DoctorSidebar />
            <div className="component_division">
              <div className="doctor_change_passwords_main_diviison">
                <div className="doctor_change_password_section">
                  <div className="doctor_change_password_section_old_password_section">
                    <p className="doctor_change_password_section_old_password_title">
                      Old Password
                    </p>
                    <div className="patient_change_password_old_password_division">
                      <input
                        class=" patient_change_password_section_input_tag1 form-control form-control-lg"
                        type={Inputtype}
                        placeholder=""
                        aria-label=".form-control-lg example"
                        onChange={(e) => {
                          setoldpassword(e.target.value);
                        }}
                      ></input>
                      <div className="patient_change_password_old_password_eye">
                        <i
                          className=" eye_icon"
                          onClick={(e) => {
                            if (Inputtype == "password") {
                              setInputtype("text");
                              setButtonName(
                                <i class="fa-solid fa-eye-slash"></i>
                              );
                            } else {
                              setInputtype("password");
                              setButtonName(<i class="fa-solid fa-eye"></i>);
                            }

                            e.preventDefault();
                          }}
                        >
                          {ButtonName}
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="doctor_change_password_section_new_password_section">
                    <p className="doctor_change_password_section_new_password_title">
                      New Password
                    </p>
                    <div className="patient_change_password_old_password_division">
                      <input
                        class=" patient_change_password_section_input_tag1 form-control form-control-lg"
                        type={Inputtypen}
                        placeholder=""
                        aria-label=".form-control-lg example"
                        onChange={(e) => {
                          setnewpassword(e.target.value);
                        }}
                      ></input>
                      <div className="patient_change_password_old_password_eye">
                        <i
                          className=" eye_icon"
                          onClick={(e) => {
                            if (Inputtypen == "password") {
                              setInputtypen("text");
                              setButtonNamen(
                                <i class="fa-solid fa-eye-slash"></i>
                              );
                            } else {
                              setInputtypen("password");
                              setButtonNamen(<i class="fa-solid fa-eye"></i>);
                            }

                            e.preventDefault();
                          }}
                        >
                          {ButtonNamen}
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="doctor_change_password_section_confirm_password_section">
                    <p className="doctor_change_password_section_confirm_password_title">
                      Confirm Password
                    </p>
                    <div className="patient_change_password_old_password_division">
                      <input
                        class=" patient_change_password_section_input_tag1 form-control form-control-lg"
                        type={Inputtypeco}
                        placeholder=""
                        aria-label=".form-control-lg example"
                        onChange={(e) => {
                          setconfirmpassword(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (newpassword === e.target.value) {
                              var Url = window.API_PREFIX + "admin/change_password";
                              dispatch({
                                type:'StartLoad'
                            })
                              fetch(Url, {
                                method: "POST",
                                headers: {
                                  "Content-type": "application/json",
                                },
                                body: JSON.stringify({
                                  Token: localStorage.getItem("DToken"),
                                  Old_Password: oldpassword,
                                  New_Password: newpassword,
                                }),
                              })
                                .then((resp) => resp.json())
                                .then((data) => {
                                  console.log(data);
                                  dispatch({
                                    type:'StopLoad'
                                })
                                  if (data["status"] === "1") {
                                    alert("Password Successfull Changed");
                                  } else {
                                    alert(data["message"]);
                                  }
                                });
                            } else {
                              alert("Password Didnt Matched");
                            }

                          }}}
                      ></input>
                      <div className="patient_change_password_old_password_eye">
                        <i
                          className=" eye_icon"
                          onClick={(e) => {
                            if (Inputtypeco == "password") {
                              setInputtypeco("text");
                              setButtonNameco(
                                <i class="fa-solid fa-eye-slash"></i>
                              );
                            } else {
                              setInputtypeco("password");
                              setButtonNameco(<i class="fa-solid fa-eye"></i>);
                            }

                            e.preventDefault();
                          }}
                        >
                          {ButtonNameco}
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="doctor_change_password_section_save_button_section">
                    <button
                      type="button"
                      class=" doctor_change_password_section_save_button btn"
                      onClick={() => {
                        if (newpassword === confirmpassword) {
                          var Url = window.API_PREFIX + "admin/change_password";
                          dispatch({
                            type:'StartLoad'
                        })
                          fetch(Url, {
                            method: "POST",
                            headers: {
                              "Content-type": "application/json",
                            },
                            body: JSON.stringify({
                              Token: localStorage.getItem("DToken"),
                              Old_Password: oldpassword,
                              New_Password: newpassword,
                            }),
                          })
                            .then((resp) => resp.json())
                            .then((data) => {
                              console.log(data);
                              dispatch({
                                type:'StopLoad'
                            })
                              if (data["status"] === "1") {
                                alert("Password Successfull Changed");
                              } else {
                                alert(data["message"]);
                              }
                            });
                        } else {
                          alert("Password Didnt Matched");
                        }
                      }}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

  );
}
