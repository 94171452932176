import './ContactUs.css';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
function ContactUs() {
    let navigate = useNavigate();

    return (
        <>
            <div>
                <div class="contactus-main-wrapper">
                    {/* breadcrumbs-start */}
                    <div className='contactus_breadcrumb_main_division'>
                        <div className='contactus_breadcrumb_division'>
                            <p className='contactus_breadcrumb_title'

                                onClick={() => { navigate("/") }}
                            >home</p>
                            <p className='contactus_breadcrumb_title1'>/</p>
                            <p className='contactus_breadcrumb_title2'
                                onClick={() => { navigate("/ContactUs") }}
                            >Contact Us</p>
                        </div>
                        <div>
                            <h1 className='contactus_breadcrumb_title_main'>contact us</h1>
                        </div>
                    </div>
                    {/* breadcrumbs-end */}

                   <div className='w3-container w3-center w3-animate-bottom'>
                   <section class="contactus-contact-section">
                        <div class="container">
                            <div class="row mb-5">
                                <div class="col-md-12 text-center">
                                    <h3 class="mb-4">Contact Us</h3>
                                    <p>Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 d-flex">
                                    <div class="contactus-contact-box flex-fill">
                                        <div class="contactus-infor-img">
                                            <div class="contactus-image-circle">
                                                <i class="fa-solid fa-phone"></i>
                                            </div>
                                        </div>
                                        <div class="contactus-infor-details text-center">
                                            <label>Phone Number</label>
                                            <p>+152 534-468-854</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex">
                                    <div class="contactus-contact-box flex-fill">
                                        <div class="contactus-infor-img">
                                            <div class="contactus-image-circle bg-primary">
                                                <i class="fa-solid fa-envelope"></i>
                                            </div>
                                        </div>
                                        <div class="contactus-infor-details text-center">
                                            <label>Email</label>
                                            <p><a href="https://doccure-laravel.dreamguystech.com/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="7f1c10110b1e1c0b3f1a071e120f131a511c1012">[email&#160;protected]</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex">
                                    <div class="contactus-contact-box flex-fill">
                                        <div class="contactus-infor-img">
                                            <div class="contactus-image-circle">
                                                <i class="fa-solid fa-location-dot"></i>
                                            </div>
                                        </div>
                                        <div class="contactus-infor-details text-center">
                                            <label>Location</label>
                                            <p>C/54 Northwest Freeway, Suite 558,
                                                Houston, USA 485</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="contactus-contact-form">
                        <div class="container">
                            <div class="contactus-section-header text-center">
                                <h2>Get in touch!</h2>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="contactus_input_text_field_division form-group">
                                                    <label>Your Name <span>*</span></label>
                                                    <input type="text" class="contactus_input_text_field form-control" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="contactus_input_text_field_division form-group">
                                                    <label>Your Email <span>*</span></label>
                                                    <input type="text" class="contactus_input_text_field form-control" />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="contactus_input_text_field_division form-group">
                                                    <label>Subject</label>
                                                    <input type="text" class="contactus_input_text_field form-control" />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="contactus_input_text_field_division form-group">
                                                    <label>Comments <span>*</span></label>
                                                    <textarea class="contactus_input_text_field form-control">

                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="btn bg-primary">Send Message</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section class="contactus-contact-map d-flex">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3459.716346058072!2d-95.5565430855612!3d29.872453233633234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640cfe4516785ed%3A0x774974592a609121!2s54%20Northwest%20Fwy%20%23558%2C%20Houston%2C%20TX%2077040%2C%20USA!5e0!3m2!1sen!2sin!4v1631855334452!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
                    </section>
                   </div>

                </div>
            </div>
        </>
    )
}

export default ContactUs;